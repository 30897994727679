import React, { Component } from 'react'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import PrimaryGuestCellHotel from './PrimaryGuestCellHotel'
import GuestsCell from '../Common/GuestsCell'
import { Button, Glyphicon } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import OrderService from '../../services/OrderService'
import { refundRequestWithOrderDetails } from '../helpers/items'
import RefundRequestReasonCell from './RefundRequestReasonCell'
import moment from 'moment'

export default class CancellationRequests extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalRefundRequests: 0,
      loading: true,
      cancellationRequests: [],
      error: null,
      downloadingAttachments: [],
    }

    this.vendorId = props.params.id_vendor

    this.approveCancellationRequest = this.approveCancellationRequest.bind(this)
    this.rejectCancellationRequest = this.rejectCancellationRequest.bind(this)
    this.downloadPdf = this.downloadPdf.bind(this)
  }

  renderOfferLink(_, row) {
    return (
      <div>
        {row.offer_id} <a href={row.offer_link} className="link" target="_blank">(View)</a>
      </div>
    )
  }

  renderPrimaryGuest(_, row) {
    return <PrimaryGuestCellHotel row={row} />
  }

  renderGuests(_, row) {
    return <GuestsCell row={row} />
  }

  renderPackage(_, row) {
    const packageDuration = row.package_nights ? 'nights' : 'days'

    return (
      <div>
        <div><strong>{row.package_name}</strong></div>
        <div>{row.package_nights || row.package_days} {packageDuration}</div>
      </div>
    )
  }

  renderStatus(_, row) {
    const statusMapping = {
      pending: 'Pending',
      approved: 'Approved',
      rejected: 'Rejected',
      cancelled: 'Withdrawn Cancellation Request',
      expired: 'Expired',
      // Vendor doesn't need to know about pending auto reject, so we display it as already auto rejected.
      auto_rejected_pending: 'Auto-Rejected',
      auto_rejected: 'Auto-Rejected',
    }

    const displayStatus = statusMapping[row.refundRequest.status] || 'Unknown'

    return <div>{displayStatus}</div>
  }

  renderReason(_, row) {
    return <RefundRequestReasonCell reasonText={row.refundRequest.reason} />
  }

  downloadPdf = async(refundRequestId, guestName, attachment, index) => {
    try {
      this.setState((prevState) => ({
        downloadingAttachments: [...prevState.downloadingAttachments, attachment],
      }))
      await OrderService.getAttachmentUrl(refundRequestId, guestName, attachment, index)
      this.setState((prevState) => ({
        downloadingAttachments: prevState.downloadingAttachments.filter(a => a !== attachment),
      }))
    } catch (e) {
      this.setState((prevState) => ({
        downloadingAttachments: prevState.downloadingAttachments.filter(a => a !== attachment),
      }))
    }
  }

  renderDocumentLink = (_, row) => {
    const { downloadingAttachments } = this.state
    const createdInLast21Days = moment(row.refundRequest.created_at).isAfter(moment().subtract(21, 'days'))

    if (row.refundRequest?.attachments?.length > 0 && createdInLast21Days) {
      return (
        <div>
          { row.refundRequest.attachments.map((attachment, index) => (
            <div key={index}>
              {downloadingAttachments.includes(attachment) && (
                <MDSpinner
                  style={{
                    display: 'block',
                    margin: '0 auto',
                  }}
                  size={30}
                />
              )}
              {!downloadingAttachments.includes(attachment) && (
                <Button
                  bsStyle="link"
                  onClick={() => this.downloadPdf(row.refundRequest.id_refund_request, row.guestname, attachment, index + 1)}
                  style={{
                    fontSize: '30px',
                    display: 'block',
                    margin: '0 auto',
                  }}
                >
                  <Glyphicon glyph="download" bsSize="large" style={{ color: 'blue' }} />
                </Button>
              )}
            </div>
          ))}
        </div>
      )
    }
    return <div>N/A</div>
  }

  renderAction = (_, row) => {
    const showActions = row.refundRequest.status === 'pending' && row.item_status !== 'cancelled'

    if (showActions) {
      return <div>
        <Button
          className="btn-dates-request"
          bsStyle="success"
          bsSize="xsmall"
          onClick={() => this.approveCancellationRequest(row.refundRequest.id_refund_request)}
        >
          Approve
        </Button>
        <Button
          className="btn-dates-request"
          bsStyle="danger"
          bsSize="xsmall"
          onClick={() => this.rejectCancellationRequest(row.refundRequest.id_refund_request)}
        >
          Reject
        </Button>
      </div>
    }

    return <div>N/A</div>
  }

  componentDidMount() {
    this.fetchData()
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage } = this.state
    try {
      const { result: refundRequests, total: totalRefundRequests } = await OrderService.getVendorRefundRequests({
        vendorId: this.vendorId,
        page,
        limit: sizePerPage,
      })

      const idOrders = refundRequests.map((refundRequest) => refundRequest.fk_order_id).join(',')

      const { result: orderMeta } = await OrderService.getCompletedVendorPurchases({ idOrders, showBNBL: true, vendorId: this.vendorId })

      const refundRequestDetails = refundRequests.reduce((acc, refundRequest) => {
        const order = orderMeta.find(o => o.id === refundRequest.fk_order_id)
        const item = order && order.items.find(item => item.id === refundRequest.fk_item_id)

        if (order && item) {
          acc.push(refundRequestWithOrderDetails(refundRequest, order, item))
        }
        return acc
      }, [])

      this.setState({
        loading: false,
        cancellationRequests: refundRequestDetails,
        totalRefundRequests: totalRefundRequests,
      })
    } catch (e) {
      this.setState({
        loading: false,
        error: e.message,
      })
    }
  }

  approveCancellationRequest = async(refundRequestId) => {
    try {
      this.setState({ loading: true })

      await OrderService.updateRefundRequest({ refundRequestId, status: 'approved' })

      this.fetchData()
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  rejectCancellationRequest = async(refundRequestId) => {
    try {
      this.setState({ loading: true })

      await OrderService.updateRefundRequest({ refundRequestId, status: 'rejected' })

      this.fetchData()
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  onPageChange = (page) => {
    this.setState({ page }, this.fetchData)
  }

  onSizePerPageList = (sizePerPage) => {
    this.setState({ sizePerPage, page: 1 }, this.fetchData)
  }

  render() {
    const {
      cancellationRequests,
      page,
      sizePerPage,
      totalRefundRequests,
      loading,
    } = this.state
    return (
      <div>
        {loading && <div className="spinner-container overlay">
          <MDSpinner className="spinner" size={100} />
        </div>}
        <BootstrapTable
          data={cancellationRequests}
          fetchInfo={{
            dataTotalSize: totalRefundRequests,
          }}
          options={{
            page,
            sizePerPage,
            onPageChange: this.onPageChange,
            onSizePerPageList: this.onSizePerPageList,
          }}
          remote
          pagination
        >
          <TableHeaderColumn dataFormat={this.renderOfferLink} width="15%">Offer ID</TableHeaderColumn>
          <TableHeaderColumn dataField="created_at" isKey>Date of request</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPrimaryGuest} width="15%">Primary Guest</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderGuests}>Guests</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPackage}>Package</TableHeaderColumn>
          <TableHeaderColumn dataField="room_name">Room name</TableHeaderColumn>
          <TableHeaderColumn dataField="check_in">Check-in</TableHeaderColumn>
          <TableHeaderColumn dataField="check_out">Check-out</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderReason} width="20%">Request reason</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderDocumentLink}>Documents</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus}>Status</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderAction}>Action</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
