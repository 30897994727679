import React, { Component } from 'react'
import { Label } from 'react-bootstrap'
import MDSpinner from 'react-md-spinner'
import { BootstrapTable, TableHeaderColumn } from 'react-bootstrap-table'
import OrderService from '../../services/OrderService'
import ErrorDisplay from '../Common/ErrorDisplay'
import { datesRequestWithOrderDetails } from '../helpers/items'
import Alert from '../Common/Alert'

export default class PromotionsPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      page: 1,
      sizePerPage: 10,
      totalDatesRequests: 0,
      loading: true,
      datesRequests: [],
      error: null,
      queryDatesRequestId: props.location.query?.datesRequestId,
    }
    this.vendorId = props.params.id_vendor
  }

  componentDidMount = async() => {
    this.fetchData()
  }

  handlePageChange = page => {
    this.setState({ page: page }, this.fetchData)
  }

  fetchData = async() => {
    this.setState({ loading: true, error: null })
    const { page, sizePerPage, queryDatesRequestId } = this.state
    try {
      const { result: datesRequests } = { result: [] }
      // await DatesRequestsService.fetchDatesRequests({ page, limit: sizePerPage, vendorId: this.vendorId })

      const queryFilterDatesRequest = queryDatesRequestId ?
        datesRequests.filter((datesRequest) => datesRequest.id_dates_request === queryDatesRequestId) : datesRequests

      const idOrders = queryFilterDatesRequest.map(d => d.fk_order_id).join(',')
      const { result: orderMeta } = queryFilterDatesRequest.length != '' ?
        await OrderService.getCompletedVendorPurchases({ idOrders, showBNBL: true, vendorId: this.vendorId }) :
        { result: [] }

      const ordersDetails = queryFilterDatesRequest.reduce((acc, datesRequest) => {
        const order = orderMeta.find(o => o.id === datesRequest.fk_order_id)
        const item = order && order.items.find(i => i.id === datesRequest.fk_item_id)
        const newAcc = item ? [...acc, datesRequestWithOrderDetails(datesRequest, order, item)] : acc
        return newAcc
      }, [])

      this.setState({
        loading: false,
        datesRequests: ordersDetails,
        totalDatesRequests: ordersDetails.length,
      })
    } catch (e) {
      this.setState({ loading: false, error: e.message })
    }
  }

  onPageChange = page => {
    this.setState({ page }, this.fetchData)
  }

  renderOfferLink(cell, row) {
    return (
      <div className="offer-id">
        {row.offer_id} <a href={row.offer_link} className="link" target="_blank">(View)</a>
      </div>
    )
  }

  renderPackage(cell, row) {
    const packageDuration = row.package_nights ? 'nights' : 'days'

    return (
      <div>
        <div><strong>{row.package_name}</strong></div>
        <div>{row.package_nights || row.package_days} {packageDuration}</div>
      </div>
    )
  }

  renderStatus = (cell, row) => {
    const statusMap = {
      pending: 'default',
      approved: 'success',
      rejected: 'danger',
      customer_booked: 'info',
      customer_cancelled: 'info',
    }
    return (
      <Label className="btn-dates-request" bsStyle={statusMap[row.status]}>{row.status}</Label>
    )
  }

  render() {
    const { datesRequests, page, sizePerPage, totalDatesRequests, loading, approvalModalShow } = this.state

    return (
      <div className="dates-requests">
        <Alert size="medium" show={approvalModalShow} onHide={() => { this.setState({ approvalModalShow: false }) }}>
          <h4>Thank you!</h4>
          <p style={{ textAlign: 'left' }}>A reminder that <b>this booking is not yet complete.</b> The Luxury Escapes customer support team has been notified to help the customer book these dates. </p>
        </Alert>
        {this.state.error && (
          <div className="container">
            <ErrorDisplay message={this.state.error} />
          </div>
        )}
        {loading && <div className="spinner-container overlay">
          <MDSpinner className="spinner" size={50} />
        </div>}
        <BootstrapTable
          data={datesRequests}
          fetchInfo={{
            dataTotalSize: totalDatesRequests,
          }}
          options={{
            page,
            sizePerPage,
            sizePerPageList: [],
            onPageChange: this.onPageChange,
          }}
          remote
          pagination
        >
          <TableHeaderColumn dataField="created_at" isKey>Date of request</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderOfferLink} width="15%">Offer ID</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderPackage}>Package</TableHeaderColumn>
          <TableHeaderColumn dataField="room_name">Room name</TableHeaderColumn>
          <TableHeaderColumn dataField="check_in">Start date</TableHeaderColumn>
          <TableHeaderColumn dataField="request_date">End date</TableHeaderColumn>
          <TableHeaderColumn dataFormat={this.renderStatus}>Status</TableHeaderColumn>
        </BootstrapTable>
      </div>
    )
  }
}
