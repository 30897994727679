import React from 'react'
import PropTypes from 'prop-types'
import MDSpinner from 'react-md-spinner'
import moment from 'moment-timezone'
import { vendor } from '@luxuryescapes/lib-global'

import OffersService from '../../services/OffersService'
import OrderService from '../../services/OrderService'
import VoucherService from '../../services/VoucherService'
import TourService from '../../services/TourService'
import ReservationService from '../../services/ReservationService'
import OrdersPage from './OrdersPage'
import TourV2OrdersPage from './TourV2OrdersPage'
import ErrorDisplay from '../Common/ErrorDisplay'
import { getItemsFromOrders } from '../helpers/getItems'
import { getTourItemsFromOrders } from '../helpers/getTourItems'
import exportBookingsToCSV from '../../exports/orders/bookings'
import exportTravellerDetailsToCSV from '../../exports/orders/traveller-details'
import { getVoucher, updateVoucherStatus } from '../helpers/vouchers'
import { OFFER_TYPE_TOUR_V2, OFFER_TYPE_TOUR } from '../../constants/offer-constants'

function chunk(array, size) {
  const chunked_arr = []
  let index = 0
  while (index < array.length) {
    chunked_arr.push(array.slice(index, size + index))
    index += size
  }
  return chunked_arr
}

export default class OrdersPageContainer extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      offer: props.location?.state?.offer ?? null,
      bookings: [],
      departureDates: null,
      loading: true,
      updatingTable: false,
      error: null,
      sizePerPage: 10,
      currentPage: 1,
      totalBookings: 0,

      maxUnloggedVouchers: 200,
      unloggedBookings: null,

      filterStatus: 'all',
      filterDepartureDate: null,
      filterBookingNumber: null,
      filterCustomer: null,
    }

    this.vendorId = props.params.id_vendor
    this.offerId = props.params.id_offer
    this.isLETour = vendor.requiresTravellerDetails(this.vendorId)
  }

  componentDidMount = async() => {
    try {
      const offer = this.state.offer ?? await this.fetchOffer()

      let departureDates = null

      if (offer.type === OFFER_TYPE_TOUR_V2) {
        departureDates = await this.fetchTourV2DepartureDates(offer)
      } else if (offer.type === OFFER_TYPE_TOUR) {
        departureDates = await this.fetchDepartureDates(offer)
      }

      const bookings = await this.fetchBookings(offer)

      this.setState({
        loading: false,
        offer,
        bookings: bookings.items,
        totalBookings: bookings.total,
        departureDates,
      })
    } catch (err) {
      this.setState({ loading: false, error: err.message })
    }
  }

  fetchOffer = async() => OffersService.getOfferById(this.offerId)

  fetchTourV2DepartureDates = (offer) => {
    const returnData = {
      future: {},
      past: {},
    }

    offer.departureDates.forEach(departureDate => {
      if (moment.utc().isAfter(departureDate)) {
        returnData.past[departureDate] = departureDate
      } else {
        returnData.future[departureDate] = departureDate
      }
    })

    this.sortDepartureDates(returnData)

    return returnData
  }

  sortDepartureDates = (departures) => {
    Object.keys(departures).forEach((type) => {
      departures[type] = Object.keys(departures[type]).sort((date1, date2) => {
        date1 = new Date(date1)
        date2 = new Date(date2)
        if (date1 > date2) return 1
        if (date1 < date2) return -1
        return 0
      })
    })
  }

  fetchDepartureDates = async(offer) => {
    const ReservationServiceData = []

    offer.packages.forEach(({ fk_tour_id: tourId, fk_tour_option_id: tourOptionId }) => {
      if (tourId && tourOptionId) {
        ReservationServiceData.push(ReservationService.getTourOptionsDates(tourId, tourOptionId))
      }
    })

    return Promise.all(ReservationServiceData).then((results) => {
      const returnData = {
        future: {},
        past: {},
      }

      results.forEach((result) => {
        if (typeof result.dates !== 'undefined') {
          result.dates.forEach(({ start_date: startDate }) => {
            if (moment.utc().isAfter(startDate)) {
              returnData.past[startDate] = startDate
            } else {
              returnData.future[startDate] = startDate
            }
          })
        }
      })

      this.sortDepartureDates(returnData)

      return returnData
    })
  }

  fetchBookings = async(offer) => {
    const { filterStatus } = this.state

    let result = null

    if (filterStatus === 'unlogged' && offer.type !== OFFER_TYPE_TOUR_V2) {
      result = await this.fetchBookingsFromVoucher(offer)
    } else {
      result = await this.fetchBookingsFromOrder(offer)
    }

    return result
  }

  fetchUnloggedBookingNumbers = async(offer) => {
    const { maxUnloggedVouchers } = this.state

    const response = await VoucherService.getUnloggedVouchers({
      vendorId: this.vendorId,
      offerId: this.offerId,
      limit: maxUnloggedVouchers,
      page: 1,
    })

    const voucherBookingNumbers = response.vouchers.map(item => item.voucher)

    // Current limit in order service - 250 vouchers
    // Limit in vendor portal - 200 vouchers
    const orderResponse = await this.fetchOrders(offer, {
      filters: { bookingNumbers: voucherBookingNumbers },
      limit: maxUnloggedVouchers,
    })

    const bookings = orderResponse.result
      .flatMap(order => order.items)
      .map(item => item.booking_number)

    // Order can contain several items, some of the can be cancelled
    return bookings.filter(bookingNumber => voucherBookingNumbers.includes(bookingNumber))
  }

  fetchBookingsFromVoucher = async(offer) => {
    const { sizePerPage, currentPage } = this.state
    let unloggedBookings = this.state.unloggedBookings

    if (unloggedBookings == null) {
      unloggedBookings = await this.fetchUnloggedBookingNumbers(offer)

      this.setState({ unloggedBookings })
    }

    if (unloggedBookings.length === 0) {
      return { items: [], total: 0 }
    }

    const currentIndex = (currentPage - 1) * sizePerPage
    const orderResponse = await this.fetchOrders(offer, {
      filters: {
        bookingNumbers: unloggedBookings.slice(currentIndex, currentIndex + sizePerPage),
      },
    })

    const hasTravellerDetails = offer.type === 'tour' && this.isLETour

    const items = await getItemsFromOrders(
      this.vendorId,
      offer,
      orderResponse.result,
      hasTravellerDetails,
      {
        bookingNumbers: unloggedBookings,
      },
    )

    return { items: items, total: unloggedBookings.length }
  }

  fetchBookingsFromOrder = async(offer) => {
    const { sizePerPage, currentPage } = this.state

    const response = await this.fetchOrders(offer, { limit: sizePerPage, page: currentPage })

    const hasTravellerDetails = [OFFER_TYPE_TOUR, OFFER_TYPE_TOUR_V2].includes(offer.type)

    let items

    if (offer.type === OFFER_TYPE_TOUR_V2) {
      items = await getTourItemsFromOrders(
        offer,
        response.result,
        hasTravellerDetails,
      )
    } else {
      items = await getItemsFromOrders(
        this.vendorId,
        offer,
        response.result,
        hasTravellerDetails && this.isLETour,
      )
    }

    return { items, total: response.total }
  }

  fetchOrders = async(offer, { limit = 10, page = 1, filters = {} }) => {
    const {
      filterBookingNumber,
      filterCustomer,
      filterDepartureDate,
    } = this.state

    if (filterBookingNumber !== null) {
      filters.bookingNumbers = [filterBookingNumber]
    }

    if (filterCustomer !== null) {
      filters.customerSearch = filterCustomer
    }

    if (filterDepartureDate !== null) {
      filters.departureDate = filterDepartureDate
    }

    return await OrderService.getCompletedVendorPurchases({
      page,
      limit,
      vendorId: this.vendorId,
      offerId: this.offerId,
      type: offer.type,
      ...filters,
    })
  }

  updateData = () => {
    const { offer } = this.state

    this.setState({ updatingData: true }, () => {
      this.fetchBookings(offer)
        .then(data => {
          this.setState({ bookings: data.items, totalBookings: data.total })
        })
        .catch(err => {
          this.setState({ error: err.message })
        })
        .finally(() => this.setState({ updatingData: false }))
    })
  }

  handlePageChange = page => {
    this.setState({ currentPage: page }, this.updateData)
  }

  handleSearchChange = term => {
    let filterBookingNumber, filterCustomer

    term = term.trim()
    const bookingNumberRegexp = /^(LE-|)[\dA-Z]{6}$/

    if (term.length === 0) {
      filterBookingNumber = null
      filterCustomer = null
    } else if (bookingNumberRegexp.exec(term) !== null) {
      filterCustomer = null
      filterBookingNumber = term
    } else {
      filterBookingNumber = null
      filterCustomer = term
    }

    this.setState({ filterBookingNumber, filterCustomer, unloggedBookings: null }, this.updateData)
  }

  onDepartureDatesChange = (date) => {
    let { filterStatus, filterDepartureDate } = this.state

    if (['all', 'unlogged'].includes(date)) {
      filterStatus = date
      filterDepartureDate = null
    } else {
      filterStatus = 'all'
      filterDepartureDate = date
    }

    this.setState({
      filterStatus,
      filterDepartureDate,
      currentPage: 1,
      unloggedBookings: null,
    }, this.updateData)
  }

  onTourV2ItemLog = async(reservationId, logged) => {
    try {
      const response = await TourService.logOrUnlogReservation(reservationId, logged)

      this.setState(prevState => ({
        bookings: prevState.bookings.map(tourItem => tourItem.reservation_id === reservationId ? { ...tourItem, logged: !!response?.logged } : tourItem),
      }))
    } catch (error) {
      this.setState({ error: error.message })
    }
  }

  onItemLog = async({ offerId, bookingNumber, isLogged }) => {
    await updateVoucherStatus({
      vendorId: this.vendorId,
      offerId,
      bookingNumber,
      isLogged,
    })

    const result = await getVoucher({
      vendorId: this.vendorId,
      offerId,
      bookingNumber,
      noCache: moment().format('x'),
    })

    this.setState(prevState => ({
      bookings: prevState.bookings.map(item => item.booking_number === bookingNumber ? { ...item, voucher: result } : item),
    }))
  }

  onUpdateTravellerData = data => {
    this.setState(prevState => ({
      bookings: prevState.bookings.map(item => item.booking_number === data.booking_number ? data : item),
    }))
  }

  exportBookings = async() => {
    const { offer } = this.state

    await exportBookingsToCSV({
      vendorId: this.vendorId,
      offer: offer,
    })
  }

  exportTravellerDetails = async() => {
    const { offer, filterDepartureDate } = this.state

    await exportTravellerDetailsToCSV({
      vendorId: this.vendorId,
      offer: offer,
      filtersData: {
        departureDate: filterDepartureDate,
      },
    })
  }

  render() {
    if (this.state.loading) {
      return (
        <div className="spinner-container">
          <MDSpinner className="spinner" size={100} />
        </div>
      )
    }

    if (this.state.error) {
      return (
        <div className="container">
          <ErrorDisplay message={this.state.error} />
        </div>
      )
    }

    const {
      offer,
      bookings,
      totalBookings,
      departureDates,
      sizePerPage,
      currentPage,
      updatingData,
    } = this.state

    if (offer.type === OFFER_TYPE_TOUR_V2) {
      return (
        <TourV2OrdersPage
          orderItems={bookings}
          totalBookings={totalBookings}
          offer={offer}
          offerId={this.offerId}
          vendorId={this.vendorId}
          departureDates={departureDates}
          isLETour={this.isLETour}
          sizePerPage={sizePerPage}
          currentPage={currentPage}
          updatingData={updatingData}
          exportBookings={this.exportBookings}
          exportTravellerDetails={this.exportTravellerDetails}
          onDepartureDatesChange={this.onDepartureDatesChange}
          onUpdateTravellerData={this.onUpdateTravellerData}
          onPageChange={this.handlePageChange}
          onSearchChange={this.handleSearchChange}
          onTourV2ItemLog={this.onTourV2ItemLog}
        />
      )
    }

    return (
      <OrdersPage
        orderItems={bookings}
        totalBookings={totalBookings}
        offer={offer}
        offerId={this.offerId}
        vendorId={this.vendorId}
        departureDates={departureDates}
        isLETour={this.isLETour}
        sizePerPage={sizePerPage}
        currentPage={currentPage}
        updatingData={updatingData}
        exportBookings={this.exportBookings}
        exportTravellerDetails={this.exportTravellerDetails}
        onDepartureDatesChange={this.onDepartureDatesChange}
        onItemLog={this.onItemLog}
        onUpdateTravellerData={this.onUpdateTravellerData}
        onPageChange={this.handlePageChange}
        onSearchChange={this.handleSearchChange}
      />
    )
  }
}

OrdersPageContainer.contextTypes = {
  user: PropTypes.object,
}
