import moment from 'moment'

import { VOUCHER, TOUR } from '../../constants/order-constants'
import { hasItemTraveller, hasItemReservation } from './validators'

export const getGuestNameForHotelReservation = (item) => {
  if (!item.reservation) {
    return 'Reservation not made yet'
  }
  return [item.reservation.guest_first_name, item.reservation.guest_last_name].join(' ')
}

export const getGuestNameForTourReservation = (traveller) => {
  if (traveller && traveller.traveller_submitted) {
    return getGuestNameForTourTraveller(traveller)
  }
  return null
}

export const getGuestNameForTourTraveller = (traveller) => {
  if (traveller) {
    return `${traveller.first_name} ${traveller.last_name}`
  }
  return null
}

export const getGuestName = (item, traveller, previous) => {
  let guestName
  if (hasItemTraveller(item)) {
    guestName = getGuestNameForTourTraveller(traveller)
  } else {
    guestName = getGuestNameForHotelReservation(item)
  }

  return guestName
}

export const getNumberOfAdults = (item) => {
  if (!item.reservation) {
    return 'Reservation not made yet'
  }
  return item.reservation.number_of_adults
}

export const getNumberOfChildren = (item) => {
  if (!item.reservation) {
    return 'Reservation not made yet'
  }
  return item.reservation.number_of_children
}

export const getNumberOfInfants = (item) => {
  if (!item.reservation) {
    return 'Reservation not made yet'
  }

  return item.reservation.number_of_infants
}

export const surchargePaidOnItem = (item) => !!(item.status === 'completed' &&
      item.reservation &&
      item.reservation.surcharge &&
      !item.reservation.surcharge_paid_direct_to_vendor)

export const formatDate = (val, addDays, format = 'YYYY-MM-DD') => {
  if (!val) {
    return null
  }
  if (!addDays) {
    return moment(val).format(format)
  }
  return moment(val).add(addDays, 'd').format(format)
}

export const getCheckInDate = (item, voucher, previous) => {
  if (!hasItemReservation(item)) {
    return null
  }

  const isVoucher = item.booking_type === VOUCHER
  const isTour = item.offer_type === TOUR

  let reservation = null
  if (typeof previous !== 'undefined' && previous === true) {
    if (item.reservation_history.length > 1) {
      for (const reservationHistoryItem of item.reservation_history) {
        if (isTour) {
          if (!moment(reservationHistoryItem.start_date).isSame(item.reservation.start_date, 'day')) {
            reservation = reservationHistoryItem
            break
          }
        } else {
          if (!moment(reservationHistoryItem.check_in).isSame(item.reservation.check_in, 'day')) {
            reservation = reservationHistoryItem
            break
          }
        }
      }

      if ((reservation && voucher.vendor_logged_at > reservation.created_at) || !reservation) {
        return null
      }
    } else {
      return null
    }
  } else {
    reservation = item.reservation
  }

  let checkInDate = null
  if (isTour) {
    checkInDate = reservation.start_date
  } else if (isVoucher) {
    checkInDate = item.voucher.check_in_date
  } else if (item.reservation_made) {
    checkInDate = reservation.check_in
  }

  return formatDate(checkInDate, false)
}

export const getCheckOutDate = (item, voucher, previous) => {
  const checkInDate = getCheckInDate(item, voucher, previous)
  if (!checkInDate) {
    return null
  }

  if (item.offer_type === TOUR) {
    // Tour day count is inclusive of the start and end date, so we must
    // add the number of days minus one to get the correct end date
    return formatDate(checkInDate, item.package_days - 1)
  }

  return formatDate(checkInDate, item.package_nights)
}

export const getCreatedAt = (item) => formatDate(item.created_at)

export const datesRequestWithOrderDetails = (datesRequest, order, item) => ({
  number_of_adults: 2,
  number_of_children: 0,
  // TODO: remove above after customer portal merged and we have this data on dates request
  ...datesRequest,
  created_at: formatDate(datesRequest.created_at, null, 'DD/MM/YYYY'),
  id_offer: datesRequest.fk_offer,
  customer_email: order.customer_email,
  customer_phone: order.customer_phone,
  customer_phone_prefix: order.customer_phone_prefix,
  guestname: order.customer_full_name,
  offer_type: order.type,
  booking_number: item.booking_number,
  package_name: item.offer_package.name,
  package_nights: item.number_of_nights,
  package_days: item.number_of_days,
  check_in: datesRequest.type === 'change_dates' && item.reservation_made ?
    item.reservation.check_in : 'N/A',
  request_date: formatDate(datesRequest.request_date, null, 'DD/MM/YYYY'),
  check_out: formatDate(datesRequest.request_date, item.number_of_nights, 'DD/MM/YYYY'),
  offer_id: item.offer.id_salesforce_external,
  offer_link: `${window.configs.LE_CUSTOMER_PORTAL}/offer/${item.offer.slug}/${item.offer.id_salesforce_external}`,
})

export const refundRequestWithOrderDetails = (refundRequest, order, item) => ({
  refundRequest: refundRequest,
  created_at: formatDate(refundRequest.created_at, null, 'DD/MM/YYYY'),
  id_offer: refundRequest.fk_offer,
  guestname: order.customer_full_name,
  offer_type: order.type,
  booking_number: item.booking_number,
  number_of_adults: item.reservation.number_of_adults,
  number_of_children: item.reservation.number_of_children,
  package_name: item.offer_package.name,
  room_name: item.offer_package.property.room_type.name,
  package_nights: item.number_of_nights,
  package_days: item.number_of_days,
  item_status: item.status,
  check_in: formatDate(item.reservation.check_in, null, 'DD/MM/YYYY'),
  check_out: formatDate(item.reservation.check_out, null, 'DD/MM/YYYY'),
  offer_id: item.offer.id_salesforce_external,
  offer_link: `${window.configs.LE_CUSTOMER_PORTAL}/offer/${item.offer.slug}/${item.offer.id_salesforce_external}`,
})
