import qs from 'qs'
import { request } from './common'

export default class DatesRequestsService {
  static fetchDatesRequests({ vendorId, page, limit = 1 } = {}) {
    const requestParams = {
      vendorId,
      page,
      limit,
    }

    return request(`${window.configs.COMMERCE_HOST}/api/orders/dates-request?${qs.stringify(requestParams)}`, { method: 'GET' })
      .then((response) => response)
  }

  static updateDatesRequests(status, datesRequestID, rejectionMessage) {
    return request(`${window.configs.COMMERCE_HOST}/api/orders/dates-request/${datesRequestID}`,
      {
        method: 'PATCH',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          status,
          rejectionMessage,
        }),
      },
    ).then((response) => response)
  }
}
